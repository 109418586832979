import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Table, Tooltip, OverlayTrigger, Dropdown, DropdownButton, Button, Form } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import { createClient } from '@supabase/supabase-js';
import GridLoader from "react-spinners/GridLoader";
import { FaInfoCircle, FaRegCopy, FaCheck, FaWindowClose } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import Coming_Soon from './coming_soon';

const maxDbControlCount = 250;

// Supabase yapılandırması
const supabaseUrl = 'https://zsmvdgwdhahncwtxrygx.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InpzbXZkZ3dkaGFobmN3dHhyeWd4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjMyMDY3MDAsImV4cCI6MjAzODc4MjcwMH0.2fCTL8cLQ8Sw3tYsNV7bq8DBfCDvvXmY6ffA0rusiDg';

const supabase = createClient(supabaseUrl, supabaseKey);

function App() {
  const [loading, setLoading] = useState(false);
  const [showSalableProducts, setShowSalableProducts] = useState(false);
  const uploadAttempt = useRef(0);
  const [data, setData] = useState([]);
  const [activeASIN, setActiveASIN] = useState('');

  const pageIsActive = localStorage.getItem("isActive") ? true : false;

  const typeInfo = {
    7: { color: '#55A605', text: 'Satılabilir' }, // Yeşil
    1: { color: '#FFD700', text: 'Beklemede' }, // Sarı
    2: { color: '#800080', text: 'Yasaklı' }, // Mor
    3: { color: '#FF6347', text: 'Hazmat' }, // Kırmızı
    4: { color: '#F28444', text: 'Yorum Silinmiş' }, // Turuncu
    5: { color: '#F2668B', text: 'Rekabet Edilmez' }, // Pembe
    6: { color: '#1A15BD', text: 'Satmaz' }, // Mavi
    8: { color: '#1A15BD', text: 'BGS-Satmaz' }, // Mavi
    9: { color: '#272424', text: 'Kapalı Kategori' }, // Siyah
  };

  const override = {
    display: "block",
    margin: "0 auto",
    zIndex: 100,
    position: 'relative'
  };

  useEffect(() => {
    if(uploadAttempt.current >= 2){
      uploadAttempt.current = 0;
    }
  },[uploadAttempt.current])

  useEffect(() => {
    filterSalableProducts();
  },[showSalableProducts])

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const binaryStr = event.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Sütun başlıklarını bulma
      const header = jsonData[0];
      const keepaIsHazmatIndex = header.findIndex((col) => col.toLowerCase().includes('is hazmat'));
      const asinIndex = header.findIndex((col) => col.toLowerCase().includes('asin'));
      const priceIndex = header.findIndex((col) => col.toLowerCase().includes('buy box: current')) > -1 ? header.findIndex((col) => col.toLowerCase().includes('buy box: current')) : header.findIndex((col) => col.toLowerCase().includes('price'));

      // Sütunları okuma
      let result = []
      if(uploadAttempt.current === 0){
        result = jsonData.slice(1).map((row,index) => ({
          CanadaASIN: row[asinIndex],
          CanadaPrice: row[priceIndex],
          UsdASIN: '',
          UsdPrice: 0,
          KeepaIsHazmat: keepaIsHazmatIndex > -1 ? row[keepaIsHazmatIndex] : '-',
          Oran: 0,
          Type: 0,
          IsShow: true,
        }));
        
      } else {
        const sliceData = jsonData.slice(1);
        result = [...data].map((row) => {
          const matchedData = sliceData.find(f => f[asinIndex] === row.CanadaASIN);
          const newObj = {
            ...row,
            UsdASIN: matchedData ? row.CanadaASIN : '-',
            UsdPrice: matchedData ? matchedData[priceIndex] : '-',
            Oran: matchedData && matchedData[priceIndex] && row.CanadaPrice ? +(row.CanadaPrice / matchedData[priceIndex]).toFixed(2) : 0
          }

          return newObj;
        });

        result = result.filter(f => f.Oran && f.Oran > 2.6).sort((a,b) => (b.Oran - a.Oran));
      }
      uploadAttempt.current = uploadAttempt.current + 1;

      setData(result);
    };

    reader.readAsBinaryString(file);
  }, [data]);

  const onClear = () => {
    uploadAttempt.current = 0;
    setData([]);
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleAsinClick = (asin) => {
    setActiveASIN(asin);
    navigator.clipboard.writeText(asin);
    const url1 = `https://www.amazon.ca/dp/${asin}`;
    const url2 = `https://www.amazon.com/dp/${asin}`;

    window.open(url2, '_blank');
    window.open(url1, '_blank');
  };

  const checkProducts = async () => {
    if(data.length === 0) {
      return;
    }
    setLoading(true);
    if(data.length > maxDbControlCount){
      const loopMaxCount = Math.ceil(data.length / maxDbControlCount);
      let loopCount = 0;
      const asins = data.map(row => row.CanadaASIN);
      while(loopCount < loopMaxCount){
        const start = (maxDbControlCount * loopCount)+(loopCount > 0 ? 1 : 0);
        const end = loopCount + 1 === loopMaxCount ? ((maxDbControlCount * loopCount + (data.length % maxDbControlCount)) - 1) : (maxDbControlCount * (loopCount + 1));
        const checkAsins = asins.slice(start, end);

        let { data: products, error } = await supabase
        .from('products')
        .select(`
          *,
          product_tags (
            *
          )
        `).in('asin',checkAsins);

        if (error) {
          console.error('Error fetching contacts:', error);
        } else {
          const newData = data.map((row, index) => {
            const check = products.find(product => product.asin === row.CanadaASIN);
            if(check){
              row.Type = check.product_tag
            }
    
            return row;
          });
    
          setData(newData);
        }

        if(loopCount + 1 === loopMaxCount){
          setLoading(false);
        }

        loopCount++;
      }
    }else {
      let { data: products, error } = await supabase
      .from('products')
      .select(`
        *,
        product_tags (
          *
        )
      `).in('asin',data.map(row => row.CanadaASIN));
  
      if (error) {
        console.error('Error fetching contacts:', error);
      } else {
        const newData = data.map((row, index) => {
          const check = products.find(product => product.asin === row.CanadaASIN);
          if(check){
            row.Type = check.product_tag
          }
  
          if(index === data.length - 1) {
            setLoading(false);
          }
  
          return row;
        });
  
        setData(newData);
      }
    }



  }

  const insertUpdateProduct = async (asin, product_tag, index) => {
    if(data[index].Type === 0){
      setLoading(true);
      const { res, error } = await supabase
      .from('products')
      .insert([
        { asin, product_tag },
      ]);
  
      if(!error) {
        data[index].Type = product_tag;
        setData([...data]);
      }
  
      setLoading(false);
    }else{
      updateProduct(asin, product_tag, index)
    }
  }

  const updateProduct = async (asin, product_tag, index) => {
    setLoading(true);
    const { res, error } = await supabase
    .from('products')
    .update([
      { product_tag },
    ])
    .eq('asin',asin)

    if(!error) {
      data[index].Type = product_tag;
      setData([...data]);
    }

    setLoading(false);
  }

  const copyASIN = (asin) => {
    toast.success("Kopyalandı", {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });
      setActiveASIN(asin);
    navigator.clipboard.writeText(asin);
  }

  const filterSalableProducts = () => {
    if(showSalableProducts) {
      const newData = data.map(row => {
        if(row.Type !== 7) {
          row.IsShow = false;
        }
  
        return row;
      });

      setData([...newData]);
    }else{
      const newData = data.map(row => {
        row.IsShow = true;
        return row;
      });

      setData([...newData]);
    }
  }

  const filterNotEqualTag = (event) => {
    if(event.target.checked){
      const newData = data.map(row => {
        if(row.Type !== 0) {
          row.IsShow = false;
        }
  
        return row;
      });

      setData([...newData]);
    }else{
      const newData = data.map(row => {
        row.IsShow = true;
        return row;
      });

      setData([...newData]);
    }
  }

  return (
    <div>
      {!pageIsActive ? <Coming_Soon /> : 
      <>
      <div {...getRootProps({ className: 'dropzone' })} style={{ border: '2px dashed #007bff', padding: '20px', cursor: 'pointer' }}>
        <input {...getInputProps()} />
        <p>{uploadAttempt.current === 0 ? 'Kanada' : 'Amerika'} excelini buraya yükleyin</p>
      </div>
      <div style={{display: 'flex', gap: '6px', justifyContent: 'flex-end', margin: '10px 0'}}>
        <div>
          <Form.Check
            style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10}}
            type="switch"
            checked={showSalableProducts}
            onChange={() => setShowSalableProducts(!showSalableProducts)}
            id="custom-switch"
            label="Satılabilir ürünleri göster"
          />
          <Form.Check
            style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10}}
            type="switch"
            onChange={filterNotEqualTag}
            id="custom-switch2"
            label="Etiketsiz ürünleri göster"
          />
        </div>
        <Button variant="danger" onClick={onClear}>Temizle</Button>
        <Button disabled={data.length === 0} variant="success" onClick={checkProducts}>Kontrol Et</Button>
      </div>
      <div>
      {loading && <div className='loader-wrapper'>
        <GridLoader
          color={'#333'}
          loading={loading}
          cssOverride={override}
          size={15}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Canada Price</th>
            <th>USD Price</th>
            <th>Canada ASIN</th>
            <th>USD ASIN</th>
            <th>Keepa Is Hazmat</th>
            <th>Oran</th>
            <th>Info</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.filter(row => row.IsShow).map((row, index) => {
            const { color, text } = row.Type !== 0 ? typeInfo[row.Type] : {color: '', text: ''};
            const softColor = color ? `${color}55` : '#F2F2F2'; // Soft renklendirme

            return (
              <tr key={index}>
                <td style={{ backgroundColor: `${softColor}`, borderColor: `${activeASIN === row.CanadaASIN ? '#000' : ''}`, borderWidth: `${activeASIN === row.CanadaASIN ? "2px" : 0}` }}>{row.CanadaPrice}</td>
                <td style={{ backgroundColor: `${softColor}`, borderColor: `${activeASIN === row.CanadaASIN ? '#000' : ''}`, borderWidth: `${activeASIN === row.CanadaASIN ? "2px" : 0}` }}>{row.UsdPrice}</td>
                <td style={{ backgroundColor: `${softColor}`, borderColor: `${activeASIN === row.CanadaASIN ? '#000' : ''}`, borderWidth: `${activeASIN === row.CanadaASIN ? "2px" : 0}` }}>
                  <div style={{display: 'flex', justifyContent: 'space-between', paddingRight: 20}}>
                    <span style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => handleAsinClick(row.CanadaASIN)}>{row.CanadaASIN}</span>
                    <span onClick={() => copyASIN(row.CanadaASIN)} style={{cursor: 'pointer'}}><FaRegCopy /></span>
                  </div>
                </td>
                <td style={{ backgroundColor: `${softColor}`, borderColor: `${activeASIN === row.CanadaASIN ? '#000' : ''}`, borderWidth: `${activeASIN === row.CanadaASIN ? "2px" : 0}` }}>
                  <span style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => handleAsinClick(row.UsdASIN)}>{row.UsdASIN}</span>
                  </td>
                <td style={{ backgroundColor: `${softColor}`, borderColor: `${activeASIN === row.CanadaASIN ? '#000' : ''}`, borderWidth: `${activeASIN === row.CanadaASIN ? "2px" : 0}` }}>{row.KeepaIsHazmat === 'yes' ? <FaCheck /> : row.KeepaIsHazmat === 'no' ? <FaWindowClose /> : '-'}</td>
                <td style={{ backgroundColor: `${softColor}`, borderColor: `${activeASIN === row.CanadaASIN ? '#000' : ''}`, borderWidth: `${activeASIN === row.CanadaASIN ? "2px" : 0}` }}>{row.Oran}</td>
                <td style={{ backgroundColor: `${softColor}`, borderColor: `${activeASIN === row.CanadaASIN ? '#000' : ''}`, borderWidth: `${activeASIN === row.CanadaASIN ? "2px" : 0}` }} align='center'>
                  {color && <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-${index}`}>{text}</Tooltip>}
                  >
                    <span style={{fontSize: 25}}><FaInfoCircle style={{ color: color }} /></span>
                  </OverlayTrigger>}
                </td>
                <td style={{ backgroundColor: `${softColor}`, borderColor: `${activeASIN === row.CanadaASIN ? '#000' : ''}`,borderWidth: `${activeASIN === row.CanadaASIN ? "2px" : 0}` }}>
                  <DropdownButton id={`dropdown-${index}`} title="Actions" variant="secondary">
                    <Dropdown.Item onClick={() => insertUpdateProduct(row.CanadaASIN, 7, index)}>{typeInfo[7].text}</Dropdown.Item>
                    <Dropdown.Item onClick={() => insertUpdateProduct(row.CanadaASIN, 1, index)}>{typeInfo[1].text}</Dropdown.Item>
                    <Dropdown.Item onClick={() => insertUpdateProduct(row.CanadaASIN, 2, index)}>{typeInfo[2].text}</Dropdown.Item>
                    <Dropdown.Item onClick={() => insertUpdateProduct(row.CanadaASIN, 3, index)}>{typeInfo[3].text}</Dropdown.Item>
                    <Dropdown.Item onClick={() => insertUpdateProduct(row.CanadaASIN, 4, index)}>{typeInfo[4].text}</Dropdown.Item>
                    <Dropdown.Item onClick={() => insertUpdateProduct(row.CanadaASIN, 5, index)}>{typeInfo[5].text}</Dropdown.Item>
                    <Dropdown.Item onClick={() => insertUpdateProduct(row.CanadaASIN, 6, index)}>{typeInfo[6].text}</Dropdown.Item>
                    <Dropdown.Item onClick={() => insertUpdateProduct(row.CanadaASIN, 8, index)}>{typeInfo[8].text}</Dropdown.Item>
                    <Dropdown.Item onClick={() => insertUpdateProduct(row.CanadaASIN, 9, index)}>{typeInfo[9].text}</Dropdown.Item>
                  </DropdownButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <ToastContainer />
      </div>
      </>}
    </div>
  );
}

export default App;
