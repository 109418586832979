function Coming_Soon() {
  return (
    <section
      style={{backgroundColor: "rgb(51, 51, 51)", color: "rgb(171, 184, 195", zIndex: 9999, width: "100%", height: "100vh"}}
    >
      <div style={{height: "100%", display: "flex", justifyContent: "center", textAlign: "center", maxWidth: "600px", margin: "0 auto"}}>
        <div>
          <img src="/coming_soon.svg" style={{width: "100%", maxWidth: "500px"}} />
          <h1 style={{marginTop: 0, fontSize: "2rem"}}>Çok Yakında</h1>
          <p>-_-</p>
        </div>
      </div>
    </section>
  );
}

export default Coming_Soon;
